import { LOGIN_PATH } from "../configs/AppConfig";
import axios from "axios";
import decode from "jwt-decode";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import firebase, { auth } from "firebase";
import FirebaseService from "./FirebaseService";
import { signInWithCustomToken } from "auth/FirebaseAuth";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return axios.post(LOGIN_PATH, data, {
    headers: {
      "Content-Type": "application/json",

    },
  });
};
JwtAuthService.logout = function () {
  localStorage.clear();
};
JwtAuthService.isAuthenticated = function () {
  let token = localStorage.getItem(AUTH_TOKEN);
  if (token == null) {
    return false;
  }
  return true;
};

JwtAuthService.saveToken = async function (jwt) {
  localStorage.setItem(AUTH_TOKEN, jwt);
  const decodedToken = decode(jwt);


  if (decodedToken.firebase_auth_token) signInWithCustomToken(decodedToken.firebase_auth_token);

  let roles1 = decodedToken.roles;
  localStorage.setItem("username", decodedToken.username);
  for (let i = 0; i < roles1.length; i++) {
    if (roles1[i] === "ADMIN") {
      localStorage.setItem("roles", JSON.stringify(decodedToken.roles));
      return true;
    } else if (roles1[i] === "COORDINATEUR") {
      localStorage.setItem("roles", JSON.stringify(decodedToken.roles));
      return true;
    } else if (roles1[i] === "COMPTABLE") {
      localStorage.setItem("roles", JSON.stringify(decodedToken.roles));
      return true;
    } else if (roles1[i] === "SECRETAIRE") {
      localStorage.setItem("roles", JSON.stringify(decodedToken.roles));
      return true;
    }
  }
};
export default JwtAuthService;
