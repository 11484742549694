import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "YATA";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const IMAGE_BASE_URL = env.IMAGE_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const ADMIN_PREFIX_PATH = "/yata";
export const AUTH_PREFIX_PATH = "/auth";

export const LOGIN_PATH = API_BASE_URL + "/login?version=2.1.0";
export const GET_NOTIFICATION_PATH = API_BASE_URL + "/user/getnotification";
export const GET_DR_DATA = API_BASE_URL + "/yata/analytics/getdr/";
export const GET_CONSULTATION_DATA = API_BASE_URL + "/yata/analytics/consul";
export const GET_CACHE_ANALYTICS = API_BASE_URL + "/yata/analytics/init";

export const GET_TOP10_CODE_ADMINISTRATION_DATA =
  API_BASE_URL + "/yata/analytics/top-code-administrator";
export const GET_TOP10_EXPERT_DATA =
  API_BASE_URL + "/yata/analytics/top-expert-doctor";
export const GET_TOP10_PRESPECTOR_DATA =
  API_BASE_URL + "/yata/analytics/top-active-doctor";

export const GET_CONSULTATION_WITH_RESPONSE_PATH =
  API_BASE_URL + "/coordinate/consultations/";
export const EDIT_CONSULTATION = API_BASE_URL + "/consultations/save/";
export const GET_CONSULTANT =
  API_BASE_URL + "/coordinate/consultations/get-consultant/";

export const ADD_DOCTOR_PATH = API_BASE_URL + ADMIN_PREFIX_PATH + "/doctor/add";
export const UPDATE_DOCTOR_PATH =
  API_BASE_URL + ADMIN_PREFIX_PATH + "/doctor/update";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "#ffffff",
  mobileNav: false,
};
