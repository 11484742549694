import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { LogoutOutlined } from '@ant-design/icons';
import ProfileImg from "../../assets/img/user.png"
import JwtAuthService from "../../services/JwtAuthService";
import {
    useHistory
} from "react-router-dom";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";

export const NavProfile = () => {
    const history = useHistory();
    const userName = localStorage.getItem("username");
    const role = JSON.parse(localStorage.getItem("roles"))[0];
    const SignOut = () => {
        JwtAuthService.logout();
        history.push(`${APP_PREFIX_PATH}`)
    }
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={45} src={ProfileImg} />
                    <div className="pl-3">
                        <h4 className="mb-0">{userName}</h4>
                        <span className="text-muted">{role}</span>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    <Menu.Item key={1} onClick={SignOut}>
                        <span>
                            <LogoutOutlined className="mr-3" />
                            <span className="font-weight-normal">Sign Out</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    <Avatar src={ProfileImg} />
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default NavProfile;
