


// REACT_APP_FIREBASE_API_KEY = AIzaSyCq_yM9HYGUNe_xRLmvD-_O7PiokOTNG1E
// REACT_APP_FIREBASE_AUTH_DOMAIN = yatamedical-69688.firebaseapp.com
// REACT_APP_FIREBASE_DATA_BASE_URL = https://yatamedical-69688.firebaseio.com
// REACT_APP_FIREBASE_PROJECT_ID = yatamedical-69688
// REACT_APP_FIREBASE_STORAGE_BUCKET = yatamedical-69688.appspot.com
// REACT_APP_FIREBASE_MESSAGING_SENDER_ID = 44298638581
// REACT_APP_FIREBASE_APP_ID = 1:44298638581:web:a439ad045f5edbaac7e1bc
// REACT_APP_FIREBASE_MEASUREMENT_ID = G-HMLTENBYL7


export default {
  apiKey: 'AIzaSyCq_yM9HYGUNe_xRLmvD-_O7PiokOTNG1E',
  authDomain: 'yatamedical-69688.firebaseapp.com',
  databaseURL:'https://yatamedical-69688.firebaseio.com',
  projectId: 'yatamedical-69688',
  storageBucket: 'yatamedical-69688.appspot.com',
  messagingSenderId:'44298638581',
  appId:'1:44298638581:web:a439ad045f5edbaac7e1bc',
  measurementId:'G-HMLTENBYL7'
};

