
export function Time(time) {

    let date = new Date().getTime() - Date.parse(time);
    let MINUTE = date / 1000 / 60
    if (MINUTE < 1) {
        return "Now";
    } else if (MINUTE < 60) {
        return MINUTE.toString().split(".")[0] + " min";
    } else if (MINUTE / 60 >= 1 && MINUTE / 60 < 24) {
        MINUTE = MINUTE / 60;
        return MINUTE.toString().split(".")[0] + " h"
    } else if (MINUTE / 60 / 24 >= 1 && MINUTE / 60 / 24 < 7) {
        MINUTE = MINUTE / 60 / 24;
        return MINUTE.toString().split(".")[0] + " jour"
    } else if (MINUTE / 60 / 24 / 7 >= 1 && MINUTE / 60 / 24 / 7 < 52) {
        MINUTE = MINUTE / 60 / 24 / 7;
        return MINUTE.toString().split(".")[0] + " sem"
    }

}