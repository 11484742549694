export const ERROR = "Error";
export const AUTHENTICATION_FAILED = "Authentication Fail";
export const LOGIN_ERROR = "Please login again";
export const NOTFOUND = "Not Found";
export const SERVER_ERROR = "Internal Server Error";
export const TIMEOUT = "Time Out";
export const ACTION_REQUIRED = "Action est obligatoire!!!";
export const REASON_CANCEL = "Motif du refus est obligatoire!!!";
export const FILL_AMOUNT = "Veuillez saissisez le montant!!!";
export const WALLET_REQUIRED = "Nom obligatoire!!!";
export const CANCEL_MESSAGE = "Vous etes sure de vouloir annuler?";
export const CODE_REQUIRED = "Code obligatoire!!!";
export const IMAGE_REQUIRED = "Image obligatoire!!!";
export const INPUT_REQUIRED = "Veuillez saissisez";
