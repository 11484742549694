import React, { useEffect, useState } from "react";
import {
  BellOutlined,
  BookOutlined,
  FileDoneOutlined,
  MoneyCollectOutlined,
  VerticalLeftOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Badge, Button, Dropdown, List, Menu } from "antd";
import Flex from "components/shared-components/Flex";
import addNotification from "react-push-notification";
import {
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
} from "../../constants/ChartConstant";
import { Time } from "../../utils/logs/Time";

const getIcon = (icon) => {
  switch (icon) {
    case "COMPTABLE":
      return <MoneyCollectOutlined color={COLOR_1} />;
    case "ADMIN":
      return <WarningOutlined color={COLOR_2} />;
    case "CONSULTATION":
      return <FileDoneOutlined color={COLOR_3} />;
    case "COORDINATEUR":
      return <FileDoneOutlined color={COLOR_4} />;
    case "APPEL":
      return <VerticalLeftOutlined color={COLOR_5} />;
    case "ARTICLE":
      return <BookOutlined color={COLOR_6} />;
    default:
      return null;
  }
};
const pushNotification = (notification) => {
  if (notification.pushed === false) {
    addNotification({
      title: notification.titre,
      subtitle: notification.type,
      message: notification.body,
      theme: "darkblue",
      native: true, // when using native, your OS will handle theming.
      silent: false,
    });
  }
};

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">{getIcon(item.type)}</div>
            <div className="mr-3">
              <span className="font-weight-bold text-dark">{item.titre}</span>
              <span className="text-gray-light">{item.body}</span>
            </div>
            <small className="ml-auto">{Time(item.time)}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   console.log("useEffect Notification");
  //   // let token = localStorage.getItem("token");
  //   // let es = new EventSourcePolyfill(GET_NOTIFICATION_PATH, {
  //   //     headers: {
  //   //         Authorization: token,
  //   //     }
  //   // });
  //   // getNotificationFromServer(es);
  //   // return () => {
  //   //     es.close();
  //   // }
  // }, []);

  // function getNotificationFromServer(es) {
  //   es.onmessage = (reponse) => {
  //     if (reponse.data !== "false") {
  //       let d = JSON.parse(reponse.data);
  //       console.log(d);
  //       if (d.length > 1) {
  //         setData(d);
  //       } else {
  //         pushNotification(d);
  //         setData((data) => [d, ...data]);
  //         console.log(data);
  //       }
  //     }
  //   };
  // }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button type="link" onClick={() => setData([])} size="small">
          Clear{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">
            View all
          </a>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
